import React from 'react';
import Select from 'react-select';
import { useGlobalState } from '../GlobalContext';
import AsyncSelect from 'react-select/async';

export const ReaSelect = (props) => {
    const [gState, ] = useGlobalState();

    const darkFont = props?.border && gState?.theme !== "dark";



    return (
        <>

            <Select
                isClearable={true}
                isSearchable={true}
                isDisabled={props?.isDisabled}
                required={"isRequired" in props ? props?.isRequired : true}

                value={props?.value}
                onChange={props?.onChange}
                options={props?.options}
                
                formatGroupLabel={props?.formatGroupLabel ? props?.formatGroupLabel : !("noGrpLbl" in props) && ((data) => (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>{data.label}</span>
                        <span style={{ backgroundColor: '#EBECF0', borderRadius: '2em', color: '#172B4D', display: 'inline-block', fontSize: 12, fontWeight: 'normal', lineHeight: '1', minWidth: 1, padding: '0.16666666666667em 0.5em', textAlign: 'center' }}>{data.options.length}</span>
                    </div>
                ))}

                classNames={{

                    control: (state) => ((props?.border ? "border " : "") + "text-sm"),

                    input: (state) => ("text-sm"),

                    singleValue: (state) => ("text-sm"),

                    option: (state) => ("text-sm" + (state.isFocused ? " text-primary bg-primary bg-opacity-10" : "")),

                    menu: (state) => ((props?.border ? "border " : "") + "text-sm rounded"),

                    placeholder: (state) => ("text-sm text-muted form-text my-auto"),

                }}

                styles={{

                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: 'rgba(255, 255, 255, .25)',
                        backgroundColor: 'inherit',
                        color: 'white',
                        maxHeight: props?.maxHeight,
                        borderRadius: "0.7rem",
                        border: props?.border ? baseStyles?.border :'none',
                        boxShadow: props?.border ? baseStyles?.boxShadow :'none',
                    }),

                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? "" : (gState?.theme === "dark" ? '#121519' : 'inherit'),
                        color: state.isFocused ? "" : (gState?.theme === "dark" ? 'white' : 'inherit'),
                        borderRadius: "0.7rem",
                    }),

                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: gState?.theme === "dark" ? '#121519' : "white",
                        color: gState?.theme === "dark" ? 'white' : 'inherit',
                    }),

                    singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: darkFont ? "black" : 'white',
                        maxHeight: props?.maxHeight,
                    }),

                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: darkFont ? "black" : 'white',
                        maxHeight: props?.maxHeight,
                    }),

                    input: (provided, state) => ({
                        ...provided,
                        color: darkFont ? "black" : 'white',
                        maxHeight: props?.maxHeight,
                    }),

                }}
                placeholder={props?.placeholder}

            />
        </>
    )
}




export const ReaSelectAsync = (props) => {
    const [gState, ] = useGlobalState();

    const darkFont = props?.border && gState?.theme !== "dark";



    return (
        <>

            <AsyncSelect
                isClearable={true}
                isSearchable={true}
                isDisabled={props?.isDisabled}
                required={"isRequired" in props ? props?.isRequired : true}
                cacheOptions = {"cacheOptions" in props && props?.cacheOptions === false ? false : true}

                onChange={props?.onChange}
                loadOptions = {props?.loadOptions}
                defaultOptions = {props?.defaultOptions}

                formatGroupLabel={props?.formatGroupLabel ? props?.formatGroupLabel : !("noGrpLbl" in props) && ((data) => (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>{data.label}</span>
                        <span style={{ backgroundColor: '#EBECF0', borderRadius: '2em', color: '#172B4D', display: 'inline-block', fontSize: 12, fontWeight: 'normal', lineHeight: '1', minWidth: 1, padding: '0.16666666666667em 0.5em', textAlign: 'center' }}>{data.options.length}</span>
                    </div>
                ))}

                classNames={{

                    control: (state) => ((props?.border ? "border " : "") + "text-sm"),

                    input: (state) => ("text-sm"),

                    singleValue: (state) => ("text-sm"),

                    option: (state) => ("text-sm" + (state.isFocused ? " text-primary bg-primary bg-opacity-10" : "")),

                    menu: (state) => ((props?.border ? "border " : "") + "text-sm rounded"),

                    placeholder: (state) => ("text-sm text-muted form-text my-auto"),

                }}

                styles={{

                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: 'rgba(255, 255, 255, .25)',
                        backgroundColor: 'inherit',
                        color: 'white',
                        maxHeight: props?.maxHeight,
                        borderRadius: "0.7rem",
                        border: props?.border ? baseStyles?.border :'none',
                        boxShadow: props?.border ? baseStyles?.boxShadow :'none',
                    }),

                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? "" : (gState?.theme === "dark" ? '#121519' : 'inherit'),
                        color: state.isFocused ? "" : (gState?.theme === "dark" ? 'white' : 'inherit'),
                        borderRadius: "0.7rem",
                    }),

                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: gState?.theme === "dark" ? '#121519' : "white",
                        color: gState?.theme === "dark" ? 'white' : 'inherit',
                    }),

                    singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: darkFont ? "black" : 'white',
                        maxHeight: props?.maxHeight,
                    }),

                    placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: darkFont ? "black" : 'white',
                        maxHeight: props?.maxHeight,
                    }),

                    input: (provided, state) => ({
                        ...provided,
                        color: darkFont ? "black" : 'white',
                        maxHeight: props?.maxHeight,
                    }),

                }}
                placeholder={props?.placeholder}

            />
        </>
    )
}


export default ReaSelect;