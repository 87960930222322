import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDQO7ZOtY-UAgJiFh2rwYGbiC03CAsa6ag",
    authDomain: "sigmavalue-b38e3.firebaseapp.com",
    projectId: "sigmavalue-b38e3",
    storageBucket: "sigmavalue-b38e3.appspot.com",
    messagingSenderId: "1021820993703",
    appId: "1:1021820993703:web:081ac980d69e2cb5915b82",
    measurementId: "G-16XTK1Y5E7"
};

const app = initializeApp(firebaseConfig);

export const GAuthProvider = new GoogleAuthProvider();
GAuthProvider && GAuthProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');

export const authFB = getAuth(app);
