import React, { useEffect, useState } from 'react';
import { getDictToParamsUrl, get_data, loading, refreshToken, setAlerts } from '../../AppUtils';
import { Navigate } from 'react-router-dom';
import { ReaSelect } from '../Utils/ReaSelect';
import { useGlobalState } from '../GlobalContext';

const IIPsearch = (props) => {
    const [gState, setGState] = useGlobalState();

    const [city, setCity_] = useState(null);
    const [iipOpts, setIipOpts] = useState(null);
    const [iipSearchOpts, setIipSearchOpts] = useState(null);
    const [iipProject, setIipProject] = useState(null);
    const [nextTo, setNextTo] = useState(null);

    const setCity = (val) => {
        if (val && val !== city) {
            let body_ = {
                City: val,
            }

            let reqOpts = {
                ...refreshToken(),
                body: JSON.stringify(body_),
            }

            get_data(process.env.REACT_APP_BACKEND_URL + "getIIPSearchOpts", reqOpts).then(data => {
                data && setIipOpts(data?.req_projects);

                // Grouped options for projects and locations
                let groupedOptions = [
                    {
                        label: "Project Name",
                        options: data?.req_projects?.projects?.map(ele => ({
                            label: ele,
                            value: ele,
                        }))
                    },
                    {
                        label: "Locality",
                        options: data?.req_projects?.locations?.map(ele => ({
                            label: ele,
                            value: ele,
                        }))
                    }
                ];

                setIipSearchOpts(groupedOptions);

            }).catch(err => {
                setIipSearchOpts(null);
                setAlerts("error", err);
            });
        }

        setCity_(val);
    }


    const onSubmit = (ev = null, hmIipSearch = null) => {
        ev?.preventDefault();
        if (iipProject || hmIipSearch) {
            let body = hmIipSearch ? {
                "city": hmIipSearch?.city,
                "projectName": hmIipSearch?.projectName,
                "selectionOf": hmIipSearch?.selectionOf,
            } : {
                "city": city,
                "projectName": iipProject.value,
                "selectionOf": iipOpts?.projects?.includes(iipProject.value) ? "projects" : "locations",
            };

            setGState({
                iipSearch: body,
                resultForIip: null,
            });

            setNextTo((iipProject?.value ? iipOpts?.projects?.includes(iipProject?.value) : (hmIipSearch && hmIipSearch?.selectionOf)) ? getDictToParamsUrl("/invest/project/", body) : getDictToParamsUrl("/invest/list/", body));
        } else {
            setAlerts("error", "Please search the Project/Locality...");
        }
    }

    useEffect(() => {
        if (gState?.hmIipSearch) {
            onSubmit(null, gState?.hmIipSearch);
        }
    }, [gState?.hmIipSearch]);



    return (
        nextTo ? <Navigate to={nextTo} /> : <>
            <div className='bg-secondary row justify-content-center' style={{ minHeight: '100vh' }}>
                {/* <!-- Header and footer --> */}
                <div className="col-md-7 col-xl-4 my-auto card text-center">
                    <div className="card-header">
                        <h4 className="card-title text-primary mb-0">Invest In Real Estate</h4>
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <form id='id_iip_search_form' method='POST' onSubmit={(ev) => onSubmit(ev)}>
                                <label className='mb-1'>
                                    <b>
                                        City
                                    </b>
                                </label>
                                <select className="form-select form-select-sm text-center mb-4" id='id_city' defaultValue={city} onChange={(ev) => setCity(ev.target.value)} required>
                                    <option value="">Choose city</option>
                                    <option value="Pune" selected={city === "Pune"}>Pune</option>
                                    {/* <option value="Mumbai" selected={city === "Mumbai"}>Mumbai</option>
                                <option value="Thane" selected={city === "Thane"}>Thane</option> */}
                                </select>

                                <label className='mb-1'>
                                    <b>
                                        Project
                                        <br />
                                        <small>
                                            * For which Invest In Real-Estate is required.
                                        </small>
                                    </b>
                                </label>
                                <ReaSelect
                                    {...props}
                                    value={iipProject}
                                    onChange={(selOpts) => setIipProject(selOpts)}
                                    options={iipSearchOpts}
                                    isDisabled={city && iipSearchOpts ? false : true}
                                    placeholder="Select a Project or Village..."
                                    border={true}
                                />

                            </form>
                        </div>
                        <button type='submit' form='id_iip_search_form' className="btn btn-primary">Search</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IIPsearch