import { toast } from "react-toastify";
import favicon from "./Components/Valuation/assets/favicon.png";
import { Link } from "react-router-dom";

export const initialState = {
    isAuth: null,
    user: null,
    appName: 'SigmaValue - Creasophere Tech Private Limited',
    appTitle: ' - Sigmavalue Micro Market Data Insights Dashboard',
    apiHost: '/',
    theme: localStorage?.getItem("theme") || 'light',
    isMobile: window.innerWidth <= 768,


    // home
    hmSearch: null,
    LWAOData: null,


    // Valuation
    valuationSearch: null,
    valuationResult: null,

    paymentData: null,


    // MMA
    searchForMicroList: null,
    selectionForMMA: null,
    selectionForMmaSt: null,
    resultForMicro: null,
    SelectionForMicro: null,
    resultForMicroSale: null,
    resultForMicroList: null,
    isMmaAllowed: null,


    // Sale Transaction
    stSearch: null,
    hmStSearch: null,
    resultForSalTra: null,

    // Invest In Real Estate
    hmIipSearch: null,
    resultForIip: null,


    // Project Details
    resultForProjInfo: null,
    selectionForProjInfo: null,


    // R&D UI
    // Location Analysis
    searchForLocAnaList: null,
    resultForLocAnaList: null,
    selectionForLocAna: null,
    resultForLocAna: null,
    // Project Analysis
    searchForProjAna: null,
    resultForProjAna: null,
    searchForTopProjRates: null,
    resultForTopProjRates: null,

};


export const setAlerts = (type_ = 'info', msg_ = 'Loading...') => {
    let toastOpts = {
        position: "top-right",
        autoClose: 20000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    };

    switch (type_) {
        case 'success':
            toast.success(msg_, toastOpts);
            break;
        case 'error':
            toast.error(msg_, toastOpts);
            break;
        case 'warning':
            toast.warning(msg_, toastOpts);
            break;

        default:
            toast.info(msg_, toastOpts);
            break;
    }
};


const loadingReqs = [];

export const loading = (act = true) => {
    console.log(loadingReqs?.length, " - Loading: ", act);
    // act && setAlerts("info", "Loading, Please wait...");
    const preloader = document.getElementById('id_page_loading');

    preloader?.classList?.contains('active') && preloader?.classList?.remove('active');

    if (act) {
        loadingReqs?.push(act);
    } else {
        loadingReqs?.pop();
    }

    if (loadingReqs?.length > 0) {
        preloader?.classList?.add('active');
    }
}


export const setDocTheme = (theme = "light") => {
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('data-bs-theme', theme);

    const themeSwitcher = document.querySelector('[data-bs-toggle="mode"]')

    if (!themeSwitcher) {
        return
    }

    const themeSwitcherCheck = themeSwitcher.querySelector('input[type="checkbox"]')

    if (theme === 'dark') {
        themeSwitcherCheck.checked = 'checked'
    } else {
        themeSwitcherCheck.checked = false
    }
}



export function toSentenceCase(string) {
    return string && string.slice(0, 1).toUpperCase() + string.slice(1);
}



export function toCapitalizeCase(text) {
    return text && text.replace(/\b\w/g, char => char.toUpperCase());
}

export function scrlTo(selector_str, scroll_at = 'center', behavior = 'smooth') {
    document?.querySelector(selector_str).scrollIntoView({
        behavior: behavior,
        block: scroll_at,
        inline: scroll_at
    });
}


// Url mainpulation utilities


export function sanitizeString(str) {
    return str.replace(/[^a-zA-Z0-9/_\- .)]/g, '');
}


export function getDictToParamsUrl(baseUrl, paramsDict) {

    var nt = `${baseUrl}?`;
    Object.keys(paramsDict).forEach(ele => {
        nt += (ele + "=" + paramsDict[ele] + "&");
    });

    return nt;
}

export function getUrlParamsDict(search = null) {
    let params = new URLSearchParams(search ? search : window.location.search);
    const queryParams = {};
    for (const [key, value] of params.entries()) {

        const safeKey = sanitizeString(key);
        const safeValue = sanitizeString(value);

        if (safeValue === 'true') {
            queryParams[safeKey] = true;

        } else if (safeValue === 'false') {
            queryParams[safeKey] = false;

        } else if (!isNaN(safeValue)) {
            queryParams[safeKey] = parseFloat(safeValue);

        } else {
            queryParams[safeKey] = safeValue;
        }


    }

    return queryParams;
}


export const updateUrlParamsOnly = (newParamsDict) => {

    const searchParams = new URLSearchParams(window.location.search);

    Object.keys(newParamsDict).forEach(ele => {
        searchParams.set(ele, newParamsDict[ele]);
    });

    window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
}

export const removeUrlParams = (newUrl = null) => {
    window.history.replaceState({}, '', newUrl ? newUrl : window?.location?.pathname);
}


export const refreshToken = (conType = 'application/json; charset=UTF-8') => {

    return {
        method: 'POST',
        headers: {
            'Content-Type': conType,
            'X-CSRFToken': getCookie('csrftoken'),
        },
        body: null,
    }

}


export const isAuthenticated = async (APIHost = '/') => {

    let reqOptions = {
        ...refreshToken(),
        method: 'GET'
    };

    const res = await fetch(`${APIHost}user/verify/`, reqOptions);

    return res.status === 200;

}



export async function get_data(url = '/', reqOptions = refreshToken()) {
    // loading(true);
    try {
        const res = await fetch(url, reqOptions);
        let data = null;
        try {
            if (res.status === 200) {
                data = await res.json();
            } else {
                data = await res.text();
            }
        } catch (err) {
            setAlerts("error", "Something went wrong...");
            console.log(err);
        }

        for (let i in data) {
            if (i === 'error') {
                setAlerts(i, `${toSentenceCase(i)}: ${data[i]}`);
            }
        }


        if (typeof data === "string") {
            // console.log("app util data:", data);
            return data?.includes("{") || data?.includes("[") ? JSON.parse(data) : data;
        } else {
            return data;
        }
    } catch (err) {
        setAlerts('error', `Something went wrong...`);
        console.log("Data Collection Error: ", err);
    } finally {
        // loading(false);
    }
}



export function get_reponse(url = '/', reqOptions = refreshToken(), on_success = async () => { }, on_final = () => { }) {
    // loading(true);

    fetch(url, reqOptions)
        .then(async res => {
            let data = await res.json();

            for (let i in data) {
                setAlerts(i, `${toSentenceCase(i)}: ${data[i]}`);
                // loading(false);
            }

            if ((res.status === 200 || res.status === 201) && 'success' in data) {
                on_success();
            }

        }).catch(res => {
            setAlerts('error', `${res}`);
            // loading(false);
        }).finally(on_final);

}

export const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


export const setCookie = (name, value, daysToExpire) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + daysToExpire);

    const cookieString = `${name}=${value};expires=${expires.toUTCString()};path=/`;

    document.cookie = cookieString;
}

export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const getGResult = (result) => {
    let ret = null;

    result.forEach(ele => {
        if (ele?.geometry.location_type === "ROOFTOP") {
            ret = ele;
        }
    });


    (ret === null) && result.forEach(ele => {
        if (ele?.geometry.location_type === "GEOMETRIC_CENTER") {
            ret = ele;
        }
    });


    (ret === null) && result.forEach(ele => {
        if (ele?.geometry.location_type === "RANGE_INTERPOLATED") {
            ret = ele;
        }
    });


    (ret === null) && result.forEach(ele => {
        if (ele?.geometry.location_type === "APPROXIMATE") {
            ret = ele;
        }
    });

    return ret ? ret : result[0];
}


export const getUsrAct = (path = "") => {

    let usr_reqs = JSON.parse(localStorage?.getItem("usr_reqs"));

    return (usr_reqs && path in usr_reqs) ? usr_reqs[path] : null;

}

export const saveUsrAct = (newAct = { "": "" }) => {

    localStorage?.setItem("usr_reqs", JSON.stringify({
        ...JSON.parse(localStorage?.getItem("usr_reqs")),
        ...newAct,
    }));

}


export const checkMmaAllowed = (setGState) => {
    get_data(process.env.REACT_APP_BACKEND_URL + "canUserViewMMA").then(data => {
        if (data && [true, false].includes(data?.userAllowed)) {
            setGState({ isMmaAllowed: data?.userAllowed });
        } else {
            setGState({ isMmaAllowed: false });
        }
    });
}


export const get_auth_and_usr = (setGState) => {

    // loading();
    isAuthenticated().then((isAuth) => {
        if (isAuth === true) {
            get_data(`/auth/user/current/`, { ...refreshToken() })
                .then((user_data) => {
                    if (user_data && user_data.length > 0 && typeof user_data === 'object') {
                        user_data = user_data[0];

                        window.isAuth = isAuth;
                        window.user = user_data;
                        window.isMmaAllowed = null;

                        setGState({
                            isAuth: isAuth,
                            user: user_data,
                            isMmaAllowed: null,
                        });
                    }
                    // loading(false);
                });
        } else {
            setGState({
                isAuth: isAuth,
            });
        }
    }).catch((err) => {
        console.log(err);
    }).finally(() => {
        // loading(false);
    });

}



// Sale Transactions common utilities..

export const formatPrice = (price) => {
    if (price >= 10000000) {
        return `${(price / 10000000).toFixed(2)} cr`;
    } else if (price >= 100000) {
        return `${(price / 100000).toFixed(2)} lakh`;
    } else {
        return `${price} approx`;
    }
};

export const formatDate = (dateString) => {
    if (!dateString) return "";

    const dateParts = dateString.split('-');
    if (dateParts.length !== 3) return "Invalid Date";

    const year = dateParts[0];
    const month = parseInt(dateParts[1], 10);
    const day = dateParts[2];

    const dateObj = new Date(`${year}-${month}-${day}`);

    if (isNaN(dateObj.getTime())) {
        return "Invalid Date";
    } else {
        return dateObj.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
    }
};


export const formatNetCarpetArea = (area) => {
    if (typeof area !== 'number' || isNaN(area)) {
        return "N/A";
    }
    return Math.round(area);
};

// MMA Sale Transactions utilities...

export const handleDownloadPDF = async (pdfContent) => {
    loading();
    const newWindow = window.open('', '_blank');
    newWindow.document.write(pdfContent + '<center><button onClick="event.target.parentElement.style.display = \'none\'; window.print(); event.target.parentElement.style.display = \'block\';" style="background-color: #FF3562; padding: 10px 20px; margin: 0 10px; color: white; border-radius: 15px; font-weight: 500; cursor: pointer; border: none;"><b>Print / Save as PDF</b></button></center>');
    newWindow.document.close();
    loading(false);
};






// Valuation

export const makePayment = async (gState, setGState, amt = 19900, onSuccess = () => { }) => {

    const initializeRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";

            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };

            document.body.appendChild(script);
        });
    };

    const res = await initializeRazorpay();

    if (!res) {
        setAlerts("error", "Razorpay Failed to load.");
        return;
    }

    // Make API call to the serverless API

    const bodyFile = {
        ...refreshToken(),
        body: JSON.stringify({ amt }),
    };

    const data = await get_data(
        process.env.REACT_APP_BACKEND_URL + "razorpayapi",
        bodyFile,
    );

    // console.log(data);

    var options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        name: "Sigmavalue - Creasophere Tech Private Limited",
        currency: data.currency,
        amount: data.amount,
        order_id: data.id,
        description: "Sigmavalue - Creasophere Tech Private Limited",
        image: favicon,
        handler: function (response) {
            // Validate payment at server - using webhooks is a better idea.
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
            if (response.razorpay_payment_id) {
                setGState({
                    paymentData: response,
                });
                setAlerts("success", "Payment Successful..");
                onSuccess();
            } else {
                setAlerts("error", "Something went wrong...");
            }
        },

        prefill: gState?.user?.fields ? {
            name: gState?.user?.fields?.first_name ? `${gState?.user?.fields?.first_name} ${gState?.user?.fields?.last_name}` : "",
            email: gState?.user?.fields?.email ? gState?.user?.fields?.email : "",
            contact: gState?.user?.fields?.mobile_number ? gState?.user?.fields?.mobile_number : "",
        } : {
            name: "",
            email: "",
            contact: "",
        },

        theme: {
            color: "#448c74",
            hide_topbar: false,
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
};



export const getValData = (gState, setGState, setNextTo) => {

    const params = getUrlParamsDict();

    if ("stSelection" in params) {
        if ("valuationPaid" in gState) {
            if (!gState?.valuationPaid) {
                return
            }
        } else {
            return
        }
    }

    let body = gState?.valuationSearch ? gState?.valuationSearch : params;
    if (!gState?.valuationResult && body) {
        loading();

        const bodyFile = {
            ...refreshToken(),
            body: JSON.stringify(body),
        };

        get_data(
            process.env.REACT_APP_BACKEND_URL + "result",
            bodyFile
        ).then((parseRes) => {
            if (parseRes?.valuation !== "na") {
                setGState({
                    valuationSearch: body,
                    valuationResult: parseRes,
                });

            } else {
                setAlerts("error", "Requested data not recived...");
            }

        }).catch((err) => {
            setAlerts("error", "Valuation: Something went wrong...");
            console.log("Valuation Error:");
            console.log(err);

        }).finally(() => {
            loading(false);
        });

    } else if (!body) {
        setAlerts("info", "Please search for property...");
        setNextTo("/valuation/");
    }

}


// export const getProjLatLngTf = (City, projName) => {
//     loading();
//     var latLngTf = null;

//     const bodyFile = {
//         ...refreshToken(),
//         body: JSON.stringify({ City, projName }),
//     };

//     get_data(
//         process.env.REACT_APP_BACKEND_URL + "getValProjLatLng",
//         bodyFile
//     ).then((data) => {
//         console.log("getProjLatLngTf:: data:", data);

//         if (data && data !== "N/A") {
//             latLngTf = data
//         } else {
//             latLngTf = {};
//         }

//     }).catch((err) => {

//         setAlerts("error", "Something went wrong in getting project details...");
//         console.log("getProjLatLng:: Error:", err);

//     }).finally(() => {
//         loading(false);
//     });

//     console.log("getProjLatLngTf:: latLngTf: ", latLngTf);

//     // while (latLngTf === null) {
//     //     if (latLngTf) {
//     //         return latLngTf;
//     //     }
//     // }

// }

export const getProjLatLngTf = async (City, projName) => {
    try {
        loading(true);

        const bodyFile = {
            ...refreshToken(),
            body: JSON.stringify({ City, projName }),
        };

        const data = await get_data(
            process.env.REACT_APP_BACKEND_URL + "getValProjLatLng",
            bodyFile
        );

        let latLngTf;
        if (data && data !== "N/A") {
            latLngTf = data;
        } else {
            latLngTf = {};
        }

        return latLngTf;
    } catch (err) {
        setAlerts("error", "Something went wrong in getting project details...");
        console.log("getProjLatLng:: Error:", err);
    } finally {
        loading(false);
    }
}

export const getDtCrd = (product, dt = null, index = null, props, gState) => {

    const shareOnWhatsApp = (projectDetails) => {
        let encodedText = encodeURIComponent(projectDetails);
        let shareUrl = gState?.isMobile ? `whatsapp://send?text=${encodedText}` : `https://web.whatsapp.com/send?text=${encodedText}`;
        window.open(shareUrl, '_blank');
    };

    const shareOnGmail = (projectDetails) => {
        let subject = encodeURIComponent("Check out this property on SigmaValue.in");
        let body = encodeURIComponent(`I found this property and thought you might be interested: ${projectDetails}`);
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    };

    const shareOnFacebook = (projectDetails) => {
        let post = encodeURIComponent(`${projectDetails}`);
        const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${post}`;
        window.open(shareUrl, '_blank');
    };

    let ret = null;
    let projectDetails = "N/A";

    if (dt && index !== null) {
        projectDetails = `Check out this property on SigmaValue.in: Project: ${dt['Project_name_Selected'][index]}, Village: ${dt['IGR_Village'][index]}, Date: ${formatDate(dt['Transaction_Date'][index])}, Price: ${formatPrice(dt['Agreement_Price_INR'][index])}, Floor: ${dt['Floor_No'][index]}. For More Details: ${window?.location?.href}`;

        const mobileClass = gState?.isMobile ? 'mobile ' : '';
        ret = (
            <div className={`card bg-dark bg-opacity-10 p-0 ${gState?.isMobile ? 'd-flex' : ''}`} style={{ borderRadius: "0.5rem" }}>
                <b className="card-header row text-primary border-primary my-0 pb-0">
                    <div className="col my-auto">
                        <i className={`bi bi-house-door fs-4 fg-primary ${gState?.isMobile ? 'fs-1' : ''}`}></i><span className='fs-4'> {(dt['Project_name_Selected'] && dt['Project_name_Selected'][index]) ? (<span style={{ fontSize: gState?.isMobile ? '1rem' : 'inherit' }}>{dt['Project_name_Selected'][index]}</span>) : "N/A"}</span>,<span className='form-text'> {(dt['IGR_Village'] && dt['IGR_Village'][index]) ? (<span style={{ fontSize: gState?.isMobile ? '0.7rem' : 'inherit' }}>{dt['IGR_Village'][index]}</span>) : "N/A"}</span>
                    </div>
                </b>
                <div className="card-body row justify-content-between py-3">
                    <div className="col my-auto">
                        <h6 className={`card-title  ${mobileClass}`} style={{ fontSize: gState?.isMobile ? '0.6rem' : 'inherit' }}>
                            {(dt['Transaction_Date'] && dt['Transaction_Date'][index]) ? (<span>{formatDate(dt['Transaction_Date'][index])}</span>) : "N/A"}
                        </h6>
                        <p className="form-text" style={{ fontSize: gState?.isMobile ? '0.5rem' : 'inherit' }}>Date</p>
                    </div>
                    <div className="col my-auto">
                        <h6 className={`card-title  ${mobileClass}`} style={{ fontSize: gState?.isMobile ? '0.6rem' : 'inherit' }}>
                            {(dt['Agreement_Price_INR'] && dt['Agreement_Price_INR'][index]) ? (<span>{formatPrice(dt['Agreement_Price_INR'][index])}</span>) : "N/A"}
                        </h6>
                        <p className="form-text" style={{ fontSize: gState?.isMobile ? '0.5rem' : 'inherit' }}>Price</p>
                    </div>
                    <div className="col my-auto">
                        <h6 className={`card-title  ${mobileClass}`} style={{ fontSize: gState?.isMobile ? '0.6rem' : 'inherit' }}>
                            {(dt['Floor_No'] && dt['Floor_No'][index]) ? (<span>{dt['Floor_No'][index]}</span>) : "N/A"}
                        </h6>
                        <p className="form-text" style={{ fontSize: gState?.isMobile ? '0.5rem' : 'inherit' }}>Floor</p>
                    </div>
                    <div className="col my-auto">
                        <h6 className="card-title " style={{ fontSize: gState?.isMobile ? '0.6rem' : 'inherit' }}>
                            {(dt['Transaction_Rate_Per_Sq_ft_on_C_A'] && dt['Transaction_Rate_Per_Sq_ft_on_C_A'][index]) ? (
                                <span>{Math.floor(dt['Transaction_Rate_Per_Sq_ft_on_C_A'][index])}</span>
                            ) : "N/A"}
                        </h6>
                        <p className="form-text" style={{ fontSize: gState?.isMobile ? '0.5rem' : 'inherit' }}>Rate/sqft(INR)</p>
                    </div>
                    <div className="col my-auto">
                        <h6 className={`card-title  ${mobileClass}`} style={{ fontSize: gState?.isMobile ? '0.6rem' : 'inherit' }}>
                            {(dt['Unit_No'] && dt['Unit_No'][index]) ? (<span>{dt['Unit_No'][index]}</span>) : "N/A"}
                        </h6>
                        <p className="form-text" style={{ fontSize: gState?.isMobile ? '0.5rem' : 'inherit' }}>Unit</p>
                    </div>
                    <div className="col my-auto">
                        <h6 className={`card-title  ${mobileClass}`} style={{ fontSize: gState?.isMobile ? '0.6rem' : 'inherit' }}>
                            {(dt['Net_Carpet_Area_sqmt_KHESTRAFAL'] && dt['Net_Carpet_Area_sqmt_KHESTRAFAL'][index]) ? (<span>{formatNetCarpetArea(dt['Net_Carpet_Area_sqmt_KHESTRAFAL'][index] * 10.764)} sqft</span>) : "N/A"}
                        </h6>
                        <p className="form-text" style={{ fontSize: gState?.isMobile ? '0.5rem' : 'inherit' }}>Net Carpet Area (sqft)</p>
                    </div>
                    <div className="col my-auto">
                        <h6 className="card-title " style={{ fontSize: gState?.isMobile ? '0.6rem' : 'inherit' }}>
                            {(dt['Transaction_Type'] && dt['Transaction_Type'][index]) ? (<span>{dt['Transaction_Type'][index]}</span>) : "N/A"}
                        </h6>
                        <p className="form-text" style={{ fontSize: gState?.isMobile ? '0.5rem' : 'inherit' }}>Document Type</p>
                    </div>
                    {/* <div className="col my-auto">
                        <h6 className="card-title " style={{ fontSize: isMobile ? '0.6rem' : 'inherit' }}>N/A</h6>
                        <p className="form-text" style={{ fontSize: isMobile ? '0.5rem' : 'inherit' }}>Tower Name</p>
                    </div> */}
                    <div className="col my-auto">
                        <h6 className="card-title">
                            <Link className="btn btn-sm btn-outline-success" to={getDictToParamsUrl(`/contact/`, {
                                msg: `Request for following Index II-    Project Name) ${dt['Project_name_Selected'][index] || "N/A"},    Date) ${dt['Transaction_Date'][index] || "N/A"}    Unit) ${dt['Unit_No'][index] || "N/A"}    Price) ${dt['Agreement_Price_INR'][index] ? formatPrice(dt['Agreement_Price_INR'][index]) : "N/A"}    Floor) ${dt['Floor_No'][index] || "N/A"}    Rate/sqft(INR)) ${dt['Transaction_Rate_Per_Sq_ft_on_C_A'][index] ? Math.round(dt['Transaction_Rate_Per_Sq_ft_on_C_A'][index] / 1000) * 1000 : "N/A"}    Net Carpet Area (sqft)) ${dt['Net_Carpet_Area_sqmt_KHESTRAFAL'][index] || "N/A"} sqft    Document Type) ${dt['Transaction_Type'][index] || "N/A"}`,
                                page: `${window?.location?.pathname}`,
                            })}>
                                Request Index II
                            </Link>
                        </h6>
                    </div>
                    <div className="col my-auto">
                        <h6 className="card-title">
                            <button className="btn btn-sm btn-outline-success" data-bs-toggle="modal" data-bs-target={`#shareModal${index}`} style={{ borderRadius: "0.5rem" }}>
                                <i className="ai-share fg-primary mx-1"></i> Share
                            </button>
                        </h6>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {ret}
            <div className="modal fade" id="indexiiModal" tabIndex="-1" aria-labelledby="indexiiModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" id="indexiiModal_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="card border-primary h-100 py-lg-3" style={{ maxWidth: "26rem" }}>
                                <div className="card-body w-100 mx-auto" style={{ maxWidth: "21rem" }}>
                                    <div className="d-flex align-items-center border-2 border-primary border-bottom pb-4 mb-4">
                                        <div className="ps-3">
                                            <h3 className="h4 text-primary mb-0">Download Index II PDF</h3>
                                            <div className="d-flex align-items-center">
                                                <span className="h5 mb-1 me-1">₹</span>
                                                <span className="h2 mb-0">45</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="list-unstyled mb-1 pb-4">
                                        <li className="d-flex pb-1 mb-2">
                                            <i className="ai-check fs-xl mt-1 me-2"></i>
                                            Get Access to Balcony area, Terrace area and Parking Area
                                        </li>
                                        <li className="d-flex pb-1 mb-2">
                                            <i className="ai-check fs-xl mt-1 me-2"></i>
                                            Government value / Circle rate
                                        </li>
                                        <li className="d-flex pb-1 mb-2">
                                            <i className="ai-check fs-xl mt-1 me-2"></i>
                                            Stamp Duty
                                        </li>
                                    </ul>
                                    {/* <button type="button" className="btn btn-primary w-100" onClick={handleGetIndexII} style={{ borderRadius: "0.5rem" }}>
                                        {gState?.isAuth ? "Download" : "Continue to SignIn and Download"}
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal modal-xl fade" id={`shareModal${index}`} tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="shareModalLabel">Share Property</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <center className="modal-body row justify-content-between">
                            <center className='col'>
                                <button className="btn btn-sm btn-outline-success d-flex justify-content-between m-2" onClick={() => shareOnWhatsApp(projectDetails)} style={{ borderRadius: "0.5rem" }}>
                                    <i className="ai-whatsapp fs-2 fg-primary mx-1"></i> Share on WhatsApp
                                </button>
                            </center>
                            <center className='col'>
                                <button className="btn btn-sm btn-outline-success d-flex justify-content-between m-2" onClick={() => shareOnGmail(projectDetails)} style={{ borderRadius: "0.5rem" }}>
                                    <i className="ai-mail-filled fs-2 fg-primary mx-1"></i> Share via Gmail
                                </button>
                            </center>
                            <center className='col'>
                                <button className="btn btn-sm btn-outline-success d-flex justify-content-between m-2" onClick={() => shareOnFacebook(projectDetails)} style={{ borderRadius: "0.5rem" }}>
                                    <i className="ai-facebook fs-2 fg-primary mx-1"></i> Share on Facebook
                                </button>
                            </center>
                        </center>
                    </div>
                </div>
            </div>
        </>
    );
};

