import React from 'react';
import { Link } from 'react-router-dom';

const ValDisclaimer = (props) => {
    return (
        <>
            <div className="row justify-content-center pt-3 mx-3">
                <div className="col-md-10 d-flex justify-content-between alert alert-info" role="alert">
                    <i className="ai-circle-info fs-xl pe-1 me-2 my-auto"></i>
                    <div className="mx-2 my-auto text-justify"><span className="fw-semibold">Disclaimer:</span> The valuations provided are approximate with a precision level ranging between 75-95%. For enhanced precision and access to Sale Transaction data, kindly <Link to="/contact" className="alert-link">reach out to us</Link> directly.</div>
                    <button type="button" className="btn-close my-auto" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
        </>
    )
}

export default ValDisclaimer