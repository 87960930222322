// Create a Context
import React, { createContext, useState, useContext } from 'react';
import { initialState } from '../AppUtils';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [globalState, setGlobalState_] = useState({
        ...initialState,
    });

    const setGlobalState = (obj) => {
        if (!window?.location?.origin?.includes('sigmavalue')) {
            console.log("gState Changed: ", obj);
        }

        setGlobalState_((prevState) => ({
            ...prevState,
            ...obj
        }));
    }



    return (
        <GlobalContext.Provider value={[globalState, setGlobalState]}>
            {children}
        </GlobalContext.Provider>
    );
};

// Custom hook for using the GlobalContext
export const useGlobalState = () => {
    return useContext(GlobalContext);
};
